import { Form, Input, InputNumber, Button, Checkbox, Typography } from 'antd';
import React from 'react';
import axios from 'axios';
import '../App.css';
import { baseURL } from '../API/url';
import {
	Redirect
} from 'react-router-dom';
import { withRouter } from "react-router";


const { Title } = Typography;

const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be equal to or less then ${len} digits',
    },
  };
  /* eslint-enable no-template-curly-in-string */
  
  const Change = (props:any) => {

    const onFinish = (values: any) => {
      const user = values.user;
      const profileUser = {
        newEmail: user.email,
        phoneNumber: user.homephone,
        street: user.street,
        city: user.city,
        province: user.province,
        postCode: user.postCode
      }
      axios.post(baseURL+"/api/changeProfile", profileUser, { withCredentials: true })		
      .then((res) => {
          
        if (res.data.error) {
            console.log(res.data.error);
            alert(res.data.error);
            return;
        }
        
     //   console.log(res.data);
        if(res.data) {
            alert('New Information Submmited By Email Successfully. We will update your profile after review');
            props.history.push('/profile');
        }
      }, (err)=>{
          console.log('[err] ' + err);
      })


    };

    return (
 
    <Form {...layout}
    //layout='vertical'
//    initialValues={{username: this.state.data.email, remember: this.state.rememberMe }}
    name="change-profile" onFinish={onFinish} validateMessages={validateMessages}>
        <Title level={2} style={{ marginTop: '50px',textAlign: 'center' }}>Change Profile</Title> 
         <Form.Item name={['user', 'email']} label="New Email" rules={[{ type: 'email' }]}>
          <Input />
        </Form.Item>
        <Form.Item name={['user', 'homephone']} label="New Phone" rules={[{ len:10 }]}>
          <Input />
        </Form.Item >
        <Title level={4} style={{ marginTop: '50px',textAlign: 'center' }}>New Address</Title> 
        <Form.Item name={['user', 'street']} label="Street">
          <Input />
       </Form.Item>
       <Form.Item name={['user', 'city']} label="City">
          <Input />
       </Form.Item>
       <Form.Item name={['user', 'province']} label="Province">
          <Input />
       </Form.Item>
       <Form.Item name={['user', 'postCode']} label="Post Code">
          <Input />
       </Form.Item>
       <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit" className="change-profile" >
            Submit Change
          </Button>        
        </Form.Item>
    </Form>
    );
  };
  
  export default withRouter(Change);